// extracted by mini-css-extract-plugin
export var automotiveBenefitsSection = "S_jw";
export var automotiveCasesSection = "S_jB";
export var automotiveClientQuotesSection = "S_jH";
export var automotiveDevExpertiseSection = "S_jF";
export var automotiveIndustriesSection = "S_jv";
export var automotiveProcessSection = "S_jx";
export var automotiveProjLogoSection = "S_jD";
export var automotiveRequirementsSection = "S_jz";
export var automotiveServicesSection = "S_jt";
export var automotiveSkilledSection = "S_jG";
export var automotiveTechStackSection = "S_jy";
export var automotiveWhyHireSection = "S_jC";